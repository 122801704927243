<template>
  <Doughnut>
      <template v-slot:title>
         <Title :id="titleId"></Title> 
      </template>
  </Doughnut>
</template>

<script>
import Doughnut from "../../atoms/Charts/Doughnut"
import Title from "../../molecules/Titles/Chart"
export default {
    name:"DoughnutDashboard",
    components: {Doughnut,Title},
    props:['titleId']
}
</script>

<style>

</style>