<template>
    <Button id="link-back-to-map" @click="$router.push('/mapa')"/>
</template>

<script>
import Button from "../atoms/BackButton"
export default {
    name:"ButtonBack",
    components: {Button},
}
</script>

<style lang="scss" scoped>

</style>