<template>
    <p class="tit">Classe Fundiária</p>
    <DynamicOptions name="sub_class" :list="getLandTenure" v-if="!loadingStatus"></DynamicOptions>
    <loader v-if="loadingStatus"></loader>
</template>

<script>
import DynamicOptions from "../../../molecules/Filters/DynamicOptions"
import { mapGetters } from 'vuex'
export default {
    name:"SizesDynamicOptions",
    components:{DynamicOptions},
    created(){
        this.$store.dispatch('filterLandTenure/getLandTenure')
    },
    computed: {
        ...mapGetters('filterLandTenure', {
            loadingStatus: 'loadingStatus',
            getLandTenure: 'getLandTenure'
        }),
    },
}
</script>

<style scoped>
.tit{
    font-family: 'Poppins', sans-serif!important;  
    font-size: 1rem;
    font-weight:bold;
}
</style>