<template>
  <div>
    <slot name="title"></slot>
    <BarChart :chartData="chartData" :options="options" v-if="chartData !== null" />
    <NoResultsTitle id="noResultsId" v-if="chartData == null || chartSum == 0"></NoResultsTitle>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { BarChart } from 'vue-chart-3';
import NoResultsTitle from '../NoResultsTitle.vue';

Chart.register(...registerables);

export default {
  name: 'App',
  components: {
    BarChart,
    NoResultsTitle
},
  data() {
    return {
      chartData: {},
    }
  },
  computed: {
    noResultsId: function() {
      // No Data
      if(this.$props.dataChart == undefined) {
        return "no-results";
      } else if(this.chartSum == 0) {
        return "no-deficit";
      } else {
        return "no-results";
      }
    },
    chartSum: function() {
      if(this.$props.dataChart == undefined) {
        return 0;
      }
      // All results are 0
      let dataSum = 0;      
      this.$props.dataChart.datasets.forEach(dt => {
         dt.data.forEach(num => {
          dataSum += parseFloat(num);
         });
      });
      return dataSum;
    }
  },
  props:['dataChart','options'],
  created() {
    if(this.$props.dataChart == undefined) {
        this.chartData = null;
    }
    else {
      this.chartData = this.$props.dataChart
    }
  }
};
</script>

<style>

</style>