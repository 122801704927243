<template>
    <div class="row mt-5 px-md-5 justify-content-center" id="dashboard">
        <div class="col-md-4 col-12">
            <Filters :currentCdMuns="currentCdMuns" :currentCdUf="currentCdUf"></Filters>
        </div>
        <div class="col-md-8 col-12 px-5">
            <div class="row justify-content-between pt-5 pt-md-0">
                <div class="col-lg-9 col-6">
                    <page-title id="title-page-1"></page-title>
                </div>
                <div class="col-lg-3 col-6">
                    <ButtonBack></ButtonBack>
                </div>
            </div>
            <div class="row justify-content-around border-bottom">
                <div class="col-12 pt-5"> 
                    <h1 class="results">Código Florestal</h1>
                </div>
                <div class="col-lg-5 col-12 my-md-5 my-3 text-center border-md-right">
                    <BigNumber :number="getBigNumberCountGid" paragraph="Imóveis" v-if="!loadingStatus" :formatNumber="false"></BigNumber>
                    <loader v-if="loadingStatus"></loader>
                </div>
                <div class="col-lg-5 col-12 my-md-5 my-3 text-center">
                    <BigNumber :number="getBigNumberTotalAreaHa" paragraph="Hectares" v-if="!loadingStatus"></BigNumber>
                    <loader v-if="loadingStatus"></loader>
                </div>
            </div>
            <div class="row justify-content-around text-center border-md-bottom">
                <div class="col-lg-5 col-12 my-md-5 mt-3 mb-0 p-3 px-5 border-md-right">
                    <VerticalBarChartGidxArea titleId="title-chart-bar-1" :dataChart="getVerticalBarChartGidxArea" v-if="!loadingStatus"></VerticalBarChartGidxArea>
                    <loader v-if="loadingStatus"></loader>
                </div>
                <div class="col-lg-5 col-12 my-md-5 my-0 p-3 px-5">
                    <DoughnutDashboard titleId="title-chart-doughnut-1" :dataChart="getDoughnutImmobileNumbers"  v-if="!loadingStatus"></DoughnutDashboard>
                    <loader v-if="loadingStatus"></loader>
                </div>
            </div>
            <div class="row justify-content-around text-center border-bottom">
                <div class="col-lg-5 col-12 my-md-5 my-0 p-3 px-5">
                    <DoughnutDashboard titleId="title-chart-doughnut-deficit-app-ha" :dataChart="getDoughnutDeficitApp"  v-if="!loadingStatus"></DoughnutDashboard>
                    <loader v-if="loadingStatus"></loader>
                </div>
                <div class="col-lg-5 col-12 my-md-5 my-0 p-3 px-5">
                    <DoughnutDashboard titleId="title-chart-doughnut-deficit-rl-ha" :dataChart="getDoughnutDeficitLR"  v-if="!loadingStatus"></DoughnutDashboard>
                    <loader v-if="loadingStatus"></loader>
                </div>
                <div class="col-12 my-5 px-5">
                     <VerticalBarChartGidxArea titleId="title-chart-bar-1" :options="{scales: {x:{stacked: true,},y: {stacked: true}}}" :dataChart="getVerticalBarChartGidxArea" v-if="!loadingStatus"></VerticalBarChartGidxArea>
                    <loader v-if="loadingStatus"></loader>                   
                </div>
            </div>
            <div class="row justify-content-around text-center">
                <div class="col-lg-5 col-12 my-md-5 mt-3 p-3">
                     <VerticalBarChartGidxArea titleId="title-chart-bar-2" :dataChart="getVerticalBarChartNV" v-if="!loadingStatus"></VerticalBarChartGidxArea>
                    <loader v-if="loadingStatus"></loader>                   
                </div>
                <div class="col-lg-5 col-12 my-md-5 p-3">
                     <VerticalBarChartGidxArea titleId="title-chart-bar-3" :dataChart="getVerticalBarChartAT" v-if="!loadingStatus"></VerticalBarChartGidxArea>
                    <loader v-if="loadingStatus"></loader>                   
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center px-md-5 mb-5">
        <hr />
        <div class="fund text-center mt-5">
            <h1 class="results text-center">{{$t('title-dashboard-table-1',['hello'])}}</h1>
            <TableDashboard :list="getLandTenure" v-if="!loadingStatusLT"></TableDashboard>
            <loader v-if="loadingStatusLT"></loader>
        </div>
        <div class="col-12 mt-5"> 
            <h1 class="results text-center">{{$t('title-table-rank',['hello'])}}</h1>
        </div>
        <div class="fund text-center">
            <Rank :currentCdMuns="currentCdMuns" :currentCdUf="currentCdUf"></Rank>   
        </div>
    </div>
</template>
<script>
import ButtonBack from "../../molecules/ButtonBack"
import PageTitle from "../../molecules/Titles/Page"
import Filters from "../../organisms/Dashboards/Filters.vue"
import BigNumber from "../../molecules/BigNumber/Box"
import DoughnutDashboard from "../../molecules/Charts/DoughnutDashboard"
import VerticalBarChartGidxArea from "../../molecules/Charts/VerticalBarChartGidxArea"
import Rank from "../../organisms/Dashboards/Rank"
import TableDashboard from "../../molecules/Tables/Dashboard"
import { mapGetters, mapMutations } from 'vuex'
export default {
    name:"TemplateDashboard",
    components:{ButtonBack,PageTitle,Filters,BigNumber,VerticalBarChartGidxArea,DoughnutDashboard,Rank,TableDashboard},
    computed: {
      ...mapGetters('dashboardForestAct', {
          loadingStatus: 'loadingStatus',
          getBigNumberCountGid: 'getBigNumberCountGid',
          getBigNumberTotalAreaHa: 'getBigNumberTotalAreaHa',
          getVerticalBarChartGidxArea: 'getVerticalBarChartGidxArea',
          getDoughnutDeficitApp: 'getDoughnutDeficitApp',
          getDoughnutDeficitLR: 'getDoughnutDeficitLR',
          getDoughnutImmobileNumbers: 'getDoughnutImmobileNumbers',
          getVerticalBarChartNV: 'getVerticalBarChartNV',
          getVerticalBarChartAT: 'getVerticalBarChartAT',
          getDashboardData: 'getDashboardData',
      }),
      ...mapGetters('dashboardLandTenure', {
          loadingStatusLT: 'loadingStatus',
          getLandTenure: 'getLandTenure'
      }),
    },
    watch: {
        $route() {
            if (this.$route.name == "dashboard") {
                this.loadData(this.$route.params.cd_mun,this.$route.params.cd_uf)
            }
        }
    },
        data() {
        return {
            currentCdMuns:[],
            currentCdUf:0,
        }
    },
    created() {
        this.loadData(this.$route.params.cd_mun,this.$route.params.cd_uf)
        this.emitter.on("dynamic-option-clicked",this.filter)
        this.emitter.on("dashboard-do-filter",this.refreshData)
        this.emitter.on("dashboard-do-clean",this.cleanUpFilters)
    },
    methods: {
        ...mapMutations({
            setLTFilter: 'dashboardLandTenure/setFilter',
            setFilter: 'dashboardForestAct/setFilter'
        }),
        loadData(cd_mun,cd_uf) {
            if (cd_mun && cd_uf) {
                this.currentCdMuns = cd_mun
                this.currentCdUf = parseInt(cd_uf)
                let locationMun = ['cd_mun',this.currentCdMuns]
                this.filterByLocation(locationMun)
            } else {
                this.refreshData(null);
            }
        },
        filter(event){
            let name = event.target.name
            let value = event.target.value
            this.setLTFilter({name,value})
            this.setFilter({name,value})
        },
        cleanUpFilters(event) {
            document.location = "/dashboard"
            event;
        },
        refreshData(event) {            
            // console.log("Refresh Data");
            this.$store.dispatch('dashboardLandTenure/getLandTenure')            
            this.$store.dispatch('dashboardForestAct/getForestAct')
            event;
        },
        filterByLocation(location){
            let name = location[0]
            let value = location[1]
            this.setLTFilter({name,value})
            this.setFilter({name,value})
            this.refreshData(null);
        }
    },
}
</script>

<style lang="scss" scoped>
    .badge-total {
        font-family: 'Poppins', sans-serif!important;  
        background: #00853F!important;
        border: 1px solid #00853F!important;
        color: #fff;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .results {
        font-family: 'Poppins', sans-serif!important;  
        font-weight: 700!important;
        font-size: 1.25rem;
        line-height: 30px;
    }
    .border-right{
        border-right: 1px dashed #525656;
    }
    .border-bottom{
        border-bottom: 1px dashed #525656!important;
    }
    .table-wrapper {
        max-height: 400px!important;
        overflow: auto;
        display: inline-block;
    }
    .fund{
        width: auto;
    }
</style>