<template>
  <Title>
     <h5>
        {{this.getText()}} 
     </h5>
  </Title>
</template>

<script>
import Title from "@/components/atoms/Title"
export default {
    name:"UfPositionTitle",
    components:{Title},
    props: {
        "Name": {
            type:String,
            required:true
        },
        "Position": {
            type:String,
            required:true
        },
    },
    methods: {
        getText: function() {
            return this.$t('position-title', { name: this.Name, pos: this.Position })
        }
    }
}
</script>

<style lang="scss">
    .title {
        &.header {
            font-size: 18px;
            line-height: 27px;
            color: #FFFFFF;  
        }
    }
        .title {
        &.header a{
            font-size: 18px;
            line-height: 27px;
            color: #FFFFFF!important;  
            text-decoration: none;
        }
    }
</style>