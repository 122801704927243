<template>
  <div class="row" id="filter-dashboard">
      <div class="col-8 search-box p-3">
        <p class="filter-tit">Filtre a sua pesquisa</p>
        <Location :currentCdUf="currentCdUf" :currentCdMuns="currentCdMuns"></Location>
        <hr class="green"/>
        <LandTenureDynamicOptions></LandTenureDynamicOptions>
        <hr class="green"/>
        <SizesDynamicOptions></SizesDynamicOptions>
        <hr class="green"/>
        <ButtonFilter />
        <ButtonClean />
      </div>
  </div>
</template>

<script>
import Location from "../../organisms/Dashboards/Filters/Location"
import LandTenureDynamicOptions from "../../organisms/Dashboards/Filters/LandTenureDynamicOptions"
import SizesDynamicOptions from "../../organisms/Dashboards/Filters/SizesDynamicOptions"
import ButtonFilter from "./Filters/ButtonFilter.vue"
import ButtonClean from "./Filters/ButtonClean.vue"

export default {
  name:"DashboardFilters",
  components:{Location,LandTenureDynamicOptions,SizesDynamicOptions,ButtonFilter,ButtonClean},
  props:["currentCdUf","currentCdMuns"]
}
</script>

<style>
  .search-box{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    width: -webkit-fill-available;
  }
  .filter-tit { 
    font-family: 'Poppins', sans-serif!important;  
    font-size: 1.125rem;
    font-weight:700;
  }
  .green{
    background: #00853F;
    opacity: 1;
  }
  .vs__dropdown-toggle{
    --vs-border-color: #00853F!important;
  }
  .vs__open-indicator {
    fill: #00853F!important;
}
  .vs__search{ 
    font-family: 'Poppins', sans-serif!important;  
    font-size: 1rem;
    font-weight:500;
  }
@media screen and (max-width: 1279px) and (min-width: 768px) {
    .search-box {
      padding: .3rem!important;
    }
    .item{
      font-size: 11px!important;
    }
    ._to-right .form-check-input {
          margin-right: 0px!important;
    }
}
@media only screen and (min-width: 1800px) {
  .search-box{
    width: 420px;
  }
}
</style>