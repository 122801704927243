<template>
    <Button id="button-dash-filter" @click="clicked"/>
</template>

<script>
import Button from "../../../atoms/Button"
export default {
    name:"ButtonFilter",
    components: {Button},
    methods: {
      clicked(event) {
        this.emitter.emit('dashboard-do-filter',event)
      }
    }
}
</script>

<style lang="scss" scoped>

</style>