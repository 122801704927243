<template>
    <loader v-if="loadingStatus"></loader>
    <v-select v-if="!loadingStatus"
        :actionHandler="handler" 
        :actionDeselect="deselected"
        :options="formatedCities"
        :allOptions="allFormatedCities"
        :selectedElements="currentCdMunsData"
        placeholder="Escolha uma cidade"
        label="nm_mun"
        inputId="cd_mun"    
    >
    </v-select>
</template>

<script>
import vSelect from "../../atoms/Form/VueSelectMulti.vue";
import { mapGetters, mapState } from 'vuex'
export default {
    name:"CitiesTypeahead",
    props:['siglaUf','currentCdMuns','currentSiglaUf'],
    data() {
        return {
            formatedCities:[],
            currentCdMunsData: this.$props.currentCdMuns,
            allFormatedCities:[]
        }
    },
    components:{vSelect},
    emits: ["selected"],
    computed: {
        ...mapState({
            states: state => state.cities.all
        }),
        ...mapGetters('cities', {
            loadingStatus: 'loadingStatus',
            getFormatedCities: 'getFormatedCities'
        }),
    },
    watch: { 
        siglaUf: function(newVal) { // watch it
            this.filterCitiesByCdUf(newVal)
        },          
        currentCdMuns: function(newVal) {
            this.currentCdMunsData = newVal  
        },
        formatedCities: function() {
            return this.getFormatedCities
        },
        loadingStatus: function() {
            if (this.loadingStatus === false) {
                this.allFormatedCities = this.getFormatedCities
                if (this.$props.siglaUf != '') {
                    this.filterCitiesByCdUf(this.$props.siglaUf)
                } else {
                    this.formatedCities = this.getFormatedCities
                }
            }
        },
    },
    created() {
        this.$store.dispatch('cities/getAllCities')
    },
    methods: {
       handler(value) {
           let cdmuns = value.map(function(vl) {
                return vl.cd_mun;               
           }); 

           this.currentCdMunsData = cdmuns;
           this.$emit('selected',  cdmuns);
       } ,
       deselected(newValue) {
           this.handler(newValue);
       },
       filterCitiesByCdUf(sigla_uf) {
           let allCities = this.getFormatedCities
           if(sigla_uf == '') {
                 this.formatedCities = allCities
           }
           else {
                this.formatedCities = allCities.filter((city) => city.sigla_uf == sigla_uf)
           }
       }
    }

}
</script>