<template>
    <div class="row">
        <div class="col-12">
            <radio class="ms-4 my-4" label="Município" @change="checkedMun($event)" name="dash-rank-localization" checked="true" id="rank-municipio" ref="rank-municipio" value="mun"></radio>
            <radio class="ms-4 my-4" label="Estado" @change="checkedUf($event)" name="dash-rank-localization" checked="false" id="rank-estado" ref="rank-estado" value="uf"></radio>
        </div>
        <div class="col-12">
            <RankPosition :selectedCities="currentCdMuns" :uf="currentCdUf" :mode="mode" :stateDataRank="getRankUf" v-if="!loadingStatusUf"/>
            <loader v-if="loadingStatusUf"></loader>
        </div>
        <div class="col-12">
            <TableDashboard :list="dataTable" v-if="!loadingStatus" 
                :enablePagination="hasPagination" :onPrevPage="onTablePrevPage" :onNextPage="onTableNextPage"></TableDashboard>
            <loader v-if="loadingStatus"></loader> 
        </div>
    </div>      
</template>

<script>
    import TableDashboard from "../../molecules/Tables/Dashboard"
    import Radio from "@/components/atoms/Form/Radiobutton.vue"
    import RankPosition from "./RankPosition.vue"
    import { mapGetters , mapMutations } from 'vuex'
    export default {
        name:"Rank",
        props:["currentCdUf","currentCdMuns"],
        components:{Radio,TableDashboard,RankPosition},
        data() {
            return {
                loadingStatus: true,
                dataTable: {},
                hasPagination: false,
                currentPage: 1,
                mode: "mun"
            }
        },
        computed: {
            ...mapGetters('dashboardRankMun', {
                loadingStatusMn: 'loadingStatusPaged',
                getRankMun: 'getRankMunPaged'
            }),
            ...mapGetters('dashboardRankUf', {
                loadingStatusUf: 'loadingStatus',
                getRankUf: 'getRankUf'
            }),   
        },
        created() {
            this.setRankPage(1);
            this.$store.dispatch('dashboardRankMun/getRankMunPaged')
            this.$store.dispatch('dashboardRankUf/getRankUf')
            this.hasPagination = true     
        },
        watch: {
            loadingStatusMn: function() {
                if (this.loadingStatusMn === false) {
                    this.loadingStatus = false         
                    this.refreshTable();         
                }
            },
            loadingStatusUf: function() {
                if (this.loadingStatusUf === false) {
                    this.loadingStatus = false
                    this.refreshTable();
                }
            },
            currentCdMuns: function() {
                //reset currentPage when cd_mun was changed
                this.currentPage = 1;
                this.onTablePageChanged(this.currentPage)
            }
        },
        methods: {
            ...mapMutations({
                setRankPage: 'dashboardRankMun/setPage',
            }),
            onTableNextPage() {
                this.currentPage++;
                this.onTablePageChanged(this.currentPage)
            },
            onTablePrevPage() {
                this.currentPage--;
                if(this.currentPage < 1) {
                    this.currentPage = 1;
                }
                this.onTablePageChanged(this.currentPage)
            },
            refreshTable() {   
                if (this.mode === "mun") {
                    this.dataTable = this.getRankMun                           
                    this.hasPagination = true     
                }
                if (this.mode === "uf") {
                    this.dataTable = this.getRankUf                          
                    this.hasPagination = false
                }
            },
            onTablePageChanged(page) {
                if (this.mode === "mun") {
                    this.setRankPage(page);
                    this.loadingStatus = true;
                    this.$store.dispatch('dashboardRankMun/getRankMunPaged')
                }
                if (this.mode === "uf") {
                    this.loadingStatus = true;
                    this.loadingStatusUf = true;
                    this.$store.dispatch('dashboardRankUf/getRankUf')  
                }
            },
            checkedMun(evt) { 
                if(evt.target !== undefined && evt.target.checked) {
                    this.mode = "mun";
                    this.refreshTable();
                }
            },
            checkedUf(evt) {   
                if(evt.target !== undefined && evt.target.checked) {
                    this.mode = "uf";
                    this.refreshTable();
                }                          
            }
        }
    }
</script>

<style scoped>
.table-wrapper {
    max-height: fit-content!important;
    display: inline!important;
}
</style>