<template>
  <Title>
     <h2 class="title page"> {{$t(id)}}</h2>
  </Title>
</template>

<script>
import Title from "@/components/atoms/Title"
export default {
    name:"TitlePage",
    props: {
    'id': {
        type:String,
        required:true
        }
    },
    components:{Title}
}
</script>

<style lang="scss">
    .title {
        &.page {
        font-family: 'Poppins', sans-serif!important;  
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 33px;
        color: #00853F;
        }
    }
</style>