<template>
    <Link id="button-dash-clean" @click="clicked" />
</template>

<script>
import Link from "../../../atoms/Link"
export default {
    name:"ButtonFilter",
    components: {Link},
    methods: {
      clicked(event) {
        this.emitter.emit('dashboard-do-clean',event)
      }
    }
}
</script>

<style lang="scss" scoped>

</style>