<template>
  <Dashboard />
</template>

<script>
import Dashboard from "../templates/Dashboard/Dashboard"
export default {
    name:"PageDashboard",
    components:{Dashboard}
}
</script>

<style>

</style>