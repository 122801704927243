<template>
    <p class="tit">Tamanho do Imóvel</p>
    <DynamicOptions name="size" :list="getSize" v-if="!loadingStatus"></DynamicOptions>
    <loader v-if="loadingStatus"></loader>
</template>

<script>
import DynamicOptions from "../../../molecules/Filters/DynamicOptions"
import { mapGetters } from 'vuex'
export default {
    name:"SizesDynamicOptions",
    components:{DynamicOptions},
    created(){
        this.$store.dispatch('filterGetSizes/getSizes')
    },
    computed: {
        ...mapGetters('filterGetSizes', {
            loadingStatus: 'loadingStatus',
            getSize: 'getSizes'
        }),
    },


}
</script>

<style scoped>
  .tit{
    font-family: 'Poppins', sans-serif!important;  
    font-size: 1rem;
    font-weight:bold;
  }
</style>