<template>
  <Checkbox class="item dash" :name="name" :id="item.value" :value="item.value" :label="item.name" checked="false" v-for="(item,index) in list" :key="index" @change="changed($event)"></Checkbox>
</template>

<script>
import Checkbox from "../../atoms/Form/Checkbox"
export default {
    name:"DynamicOptions",
    props:['name','list'],
    components:{Checkbox},
    methods: {
      changed(event) {
        this.emitter.emit('dynamic-option-clicked',event)
      }
    }
}
</script>

<style scoped>
  .item{
    font-family: 'Poppins', sans-serif!important;  
    font-size: .875rem;
    font-weight:400;
  }
</style>