<template>  
    <UfPositionTitle v-if="ufName != null" :Name="ufName" :Position="ufPosition" />
    <div v-if="!loadingStatus">
        <CityPositionTitle v-for="(item,index) in citiesData" :key="index" :cityName="item.name" :cityPosition="item.position"/>
    </div>
    <loader v-if="loadingStatus"></loader>
</template>

<script>
import CityPositionTitle from "../../molecules/Titles/CityPosition.vue"
import UfPositionTitle from "../../molecules/Titles/UfPosition.vue"
import { slugify } from '../../../modules/text.utils'
import { mapGetters, mapMutations } from 'vuex'
import Loader from '../../atoms/Loader.vue'

export default {
    name:"RankPosition",
    props: {
          "stateDataRank": {
              type:Object,
              required:false
          },
          "selectedCities": {
              required:false
          },
          "uf" : {
              required:false
          },
          "mode" :{
              type:String,
              required:false
          }
    },
    data() {
        return {
            citiesData:[],
            ufName: null,
            ufPosition: 0
        }
    },
    components:{CityPositionTitle,UfPositionTitle, Loader},
    computed: {
        ...mapGetters('cities', {
            getFilteredCities: 'getFilteredCities'
        }),
        ...mapGetters('states', {
            getFilteredStates: 'getFilteredStates'
        }),
        ...mapGetters('dashboardRankMun', {
            getRankMun: 'getRankMun',
            loadingStatus: 'loadingStatus'
        }),
    },
    created() {    
        this.setFilter(this.$props.selectedCities);     
        this.$store.dispatch('dashboardRankMun/getRankMun')
    },
     watch: { 
        selectedCities: function(newvl) {
            if(newvl !== null) {
                this.setFilter(this.$props.selectedCities);  
                this.citiesData = []
                this.ufName = null
                if(this.$props.mode == "mun") {
                    this.loadCityData(this.getRankMun)
                }
            }
        },
        stateDataRank: function(newvl) {
            if(this.$props.mode == "uf") {
                this.loadUfData(newvl)
            }
        },
        loadingStatus: function() {
            if (!this.loadingStatus) {
                this.loadCityData(this.getRankMun)
            }
        }
    },
    methods: {         
        ...mapMutations({
            setFilter: 'cities/setFilter',
            setUfFilter: 'states/setFilter',
        }),
        loadUfData: function(dataTable) {
            if(this.$props.uf !== undefined) {
                this.setUfFilter([this.$props.uf]);
                let filteredState = this.getFilteredStates;

                let cUfName = null;
                let cUfPos = 0;
                let rows = dataTable.table[0].body;
                
                rows.forEach(elm => {
                    let elmSlug = slugify(elm["column2"]);
                    let searchFilteredCity = filteredState.filter(c => c.slug_uf == elmSlug);
                    if(searchFilteredCity.length > 0) {
                       cUfName = elm["column2"];
                       cUfPos = parseInt(elm["column1"]).toString();
                    }
                });

                this.ufName = cUfName;
                this.ufPosition = cUfPos;
            }

            return dataTable;
        },
        loadCityData: function(dataTable) {            
            let filteredCities = this.getFilteredCities

            let data = new Array();
            let rows = dataTable;
                                
            rows.forEach(elm => {
                let elmSlug = slugify(elm["Município"]);
                let searchFilteredCity = filteredCities.filter(c => c.slug_mun == elmSlug);
                if(searchFilteredCity.length > 0) {
                    data.push({
                        name: elm["Município"],
                        position: parseInt(elm["Ranking"]).toString()
                    })
                }
            });

            this.citiesData = data;
        },
    },
}
</script>