<template>
    <v-select
        v-model="selected"
        :options="options"
        :label="label"
        :inputId="inputId"
        multiple 
        @option:selected="actionHandler"
        @update:modelValue="tagRemoved"
    >
    </v-select>
</template>

<script>
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
export default {
    name:"VueSelect",
    components:{vSelect},
    data() {
        return {
            selected: this.getSelected()
        }
    },
    props:{
        "actionHandler":{
            type:Function,
            required:true
        },
        "actionDeselect":{
            type:Function,
            required:false
        },
        "options":{
            type:Array,
            required:true
        },
        "allOptions":{
            type:Array,
            required:false
        },        
        "label":{
            type:String,
            required:true
        },
        "inputId":{
            type:String,
            required:true
        },
        "selectedElements":{
            required:false,
            type: Array
        }
    },
    watch: {
        selectedElements: function() {
            this.selected = this.getSelected()     
        }
    },
    methods: {
        getSelected() {
            let options = []
            if (this.$props.allOptions) {
                options = this.$props.allOptions
            } else {
                 options = this.$props.options
            }
            let id = this.$props.inputId;
            let elms = this.$props.selectedElements;
            let opts = options.filter(function(option) { 
                return elms.includes(option[id].toString())
            })

            return opts;
        },
        tagRemoved(newValue) {
            this.$props.actionDeselect(newValue);
        },
    }
}
</script>