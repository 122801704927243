<template>
    <div id="locations">
        <form>
            <div class="my-3">
                <states @selected="filterByStates" :currentCdUf="currentCdUf"></states>
            </div>
            <div class="mb-3">
                <cities @selected="filterByCities" :siglaUf="sigla_uf" :currentCdMuns="currentCdMuns" :currentSiglaUf="currentSiglaUf"></cities>
            </div>
        </form>
    </div>
</template>

<script>
import States from  '../../../molecules/Thypeaheads/States'
import Cities from  '../../../molecules/Thypeaheads/CitiesMulti'
import { mapGetters } from 'vuex'

export default {
    name:"Locations",
    components:{States,Cities},
    props:{
     currentCdUf:{
         required:false
     },
     currentCdMuns:{
        required:false
     }
    },
    data() {
        return {
            sigla_uf: '',
            cd_mun: [],
            currentSiglaUf: '',
            currentCdUfSelected: ''
        }
    },
    computed: {
        ...mapGetters('geoserver', {
            loadingStatus: 'loadingStatus'
        })
    },
    watch: {
        loadingStatus: function() {
            if (this.loadingStatus === false) {
                console.log("whatch")
            }
        }
    },
    methods: {
        filterByCities(cd_mun) {
            this.cd_mun = cd_mun
            let cd_uf = this.currentCdUfSelected
            if (this.currentCdUfSelected == '') {
                cd_uf = this.$props.currentCdUf
            }
            
            //change URL
            this.$router.push({ name: 'dashboard', params: { cd_uf: cd_uf, cd_mun:cd_mun } })
        },
        filterByStates(ufArr) {
            // let cd_uf = ufArr[0]
            let sigla_uf = ufArr[1]
            this.sigla_uf = sigla_uf
            this.currentCdUfSelected = ufArr[0]
            // console.log("console.log(ufArr)")
            // console.log(ufArr)
            // console.log(this.currentCdUfSelected == '')
            // if (this.currentCdUfSelected == '') {
            //     this.cd_uf = ''
            // } else {
            //     this.cd_uf = this.currentCdUfSelected
            // }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>