
<template>
  <bar :dataChart="dataChart" :options="options">
      <template v-slot:title>
         <Title :id="titleId"></Title>
      </template>
  </bar>
</template>

<script>
import Bar from "../../atoms/Charts/Bar"
import Title from "../../molecules/Titles/Chart"
export default {
    name:"VerticalBarChartGidxArea",
    components: {Bar,Title},
    props:['dataChart','options','titleId']
}
</script>

<style>

</style>